import { lazy } from "react";
const HomePage = lazy(() => import("pages/home"));
const AuthorRoutes = lazy(() => import("pages/authors"));
const BookRoutes = lazy(() => import("pages/book"));
const BookmarkListRoutes = lazy(() => import("pages/bookmark"));
const PublishingRoutes = lazy(() => import("pages/publishing"));
const BasketRoutes = lazy(() => import("pages/basket"));
const ProfileRoutes = lazy(() => import("pages/profile"));
const NotFound = lazy(() => import("pages/notFound/container"));
const NewsRoutes = lazy(() => import("pages/news"));
const OfflineRoutes = lazy(() => import("pages/offline"));

export const RouteList = [
  {
    element: <OfflineRoutes />,
    path: "/offline",
    isPrivate: false,
  },
  {
    element: <HomePage />,
    path: "/",
    isPrivate: false,
  },
  {
    element: <AuthorRoutes />,
    path: "/authors/*",
    isPrivate: false,
  },
  {
    element: <BookRoutes />,
    path: "/books/*",
    isPrivate: false,
  },
  {
    element: <BookRoutes />,
    path: "/discounts/*",
    isPrivate: false,
  },
  {
    element: <BookRoutes />,
    path: "/packages/*",
    isPrivate: false,
  },

  {
    element: <BookmarkListRoutes />,
    path: "/bookmark/*",
    isPrivate: false,
  },
  {
    element: <PublishingRoutes />,
    path: "/publishing",
    isPrivate: false,
  },
  {
    element: <BasketRoutes />,
    path: "/cart/*",
    isPrivate: false,
  },

  {
    element: <ProfileRoutes />,
    path: "/profile/*",
    isPrivate: true,
  },
  {
    element: <NewsRoutes />,
    path: "/news/*",
    isPrivate: false,
  },
  {
    element: <NotFound />,
    path: "not-found",
  },
  {
    element: <NotFound />,
    path: "*",
  },
];

export const AUTH_ROLE_PATH_LIST = ["/sign-in", "/sign-up"];
