import { BOOKMARK_KEY } from "contants/storage";
import get from "lodash.get";
import { IBookmark } from "types/common";

export const defaultIsBookmark = (_id: string) => {
  const storageBookMark = localStorage.getItem(BOOKMARK_KEY);
  if (!storageBookMark) return;
  return !!(JSON.parse(storageBookMark) as IBookmark[])?.find(
    (bookmark) => get(bookmark, "_id") === _id
  );
};
