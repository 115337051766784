import React from "react";

const LoactionIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03 1.99535e-05C11.29 0.01002 13.45 0.91002 15.03 2.49002C16.62 4.08002 17.51 6.23002 17.5 8.46002V8.51002C17.44 11.54 15.74 14.33 13.62 16.51C12.42 17.74 11.09 18.83 9.64 19.75C9.25 20.08 8.68 20.08 8.29 19.75C6.14 18.35 4.24 16.59 2.7 14.54C1.35 12.76 0.58 10.62 0.5 8.39002C0.52 3.74002 4.34 -0.00998005 9.03 1.99535e-05ZM9.02984 11.2597C9.73984 11.2597 10.4198 10.9897 10.9198 10.4997C11.4398 9.98967 11.7308 9.31067 11.7308 8.59967C11.7308 7.11967 10.5198 5.92967 9.02984 5.92967C7.53984 5.92967 6.33984 7.11967 6.33984 8.59967C6.33984 10.0607 7.51984 11.2397 8.99984 11.2597H9.02984Z" fill="white" />
    </svg>

  );
};

export default LoactionIcon;
