import React from 'react'

const HeartIcon = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.62269 17.2508L9.9998 17.4945L10.3769 17.2507C11.7308 16.3759 13.6853 14.978 15.2852 13.2965C17.2003 11.2837 18.1998 9.30198 18.1998 7.40808C18.1998 4.72568 16.0178 2.5437 13.3354 2.5437C12.558 2.5437 11.8138 2.72213 11.1252 3.07394C10.7156 3.28321 10.3354 3.55234 9.9998 3.86844C9.66415 3.55235 9.28402 3.28321 8.87437 3.07394C8.18582 2.72213 7.44161 2.5437 6.66415 2.5437C3.98178 2.5437 1.7998 4.72568 1.7998 7.40808C1.7998 9.30198 2.7993 11.2838 4.71439 13.2965C6.31431 14.978 8.26882 16.3759 9.62269 17.2508ZM5.72118 12.3386C7.0822 13.769 8.73549 14.9946 9.99981 15.8362C11.2641 14.9947 12.9174 13.769 14.2785 12.3386C15.9797 10.5506 16.8101 8.89265 16.8101 7.40808C16.8101 5.49231 15.2512 3.93339 13.3354 3.93339C12.2319 3.93339 11.2195 4.43956 10.5554 5.32364L9.9998 6.06318L9.44425 5.32364C8.78013 4.43956 7.76771 3.93339 6.66418 3.93339C4.74841 3.93339 3.1895 5.49231 3.1895 7.40808C3.1895 8.89265 4.01988 10.5506 5.72118 12.3386Z" fill="#1E1E1E" />
        </svg>
    )
}

export default HeartIcon