import React from 'react'

const FacebookIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1743 5.32083H17V2.14083C16.685 2.0975 15.6018 2 14.3402 2C11.7079 2 9.90476 3.65583 9.90476 6.69917V9.5H7V13.055H9.90476V22H13.4661V13.0558H16.2534L16.6959 9.50083H13.4653V7.05167C13.4661 6.02417 13.7428 5.32083 15.1743 5.32083Z" fill="#989898" />
        </svg>

    )
}

export default FacebookIcon