import React from 'react'

const BasketIcon = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.0002 11.1179C9.14774 11.1189 8.32127 10.8244 7.66154 10.2845C7.0018 9.74466 6.54961 8.99279 6.38196 8.15696C6.36859 8.06891 6.37453 7.97902 6.39936 7.8935C6.42419 7.80798 6.46731 7.72888 6.52575 7.66168C6.58418 7.59448 6.65653 7.54079 6.73777 7.50432C6.81902 7.46786 6.90722 7.4495 6.99626 7.4505C7.14261 7.44838 7.28491 7.49857 7.39753 7.59205C7.51016 7.68553 7.5857 7.81614 7.61057 7.96038C7.72626 8.51259 8.02846 9.00817 8.46641 9.36387C8.90436 9.71957 9.45138 9.91373 10.0156 9.91373C10.5798 9.91373 11.1268 9.71957 11.5647 9.36387C12.0027 9.00817 12.3049 8.51259 12.4206 7.96038C12.4455 7.81614 12.521 7.68553 12.6336 7.59205C12.7462 7.49857 12.8885 7.44838 13.0349 7.4505C13.1239 7.4495 13.2121 7.46786 13.2934 7.50432C13.3746 7.54079 13.447 7.59448 13.5054 7.66168C13.5638 7.72888 13.607 7.80798 13.6318 7.8935C13.6566 7.97902 13.6626 8.06891 13.6492 8.15696C13.4805 8.99799 13.0238 9.75386 12.3578 10.2943C11.6917 10.8348 10.858 11.1261 10.0002 11.1179Z" fill="#1E1E1E" />
            <path d="M13.6861 5.54627H12.4575V4.47124C12.4575 3.81954 12.1986 3.19454 11.7378 2.73372C11.277 2.2729 10.652 2.01401 10.0003 2.01401C9.34859 2.01401 8.72359 2.2729 8.26277 2.73372C7.80195 3.19454 7.54307 3.81954 7.54307 4.47124V5.54627H6.31445V4.47124C6.31445 3.49369 6.70278 2.55619 7.39401 1.86496C8.08524 1.17373 9.02275 0.7854 10.0003 0.7854C10.9778 0.7854 11.9153 1.17373 12.6066 1.86496C13.2978 2.55619 13.6861 3.49369 13.6861 4.47124V5.54627Z" fill="#1E1E1E" />
            <path d="M15.4674 19.2145H4.53272C4.28276 19.2149 4.03534 19.1643 3.80552 19.066C3.5757 18.9677 3.36828 18.8236 3.19589 18.6426C3.0235 18.4616 2.88973 18.2474 2.80273 18.0131C2.71573 17.7787 2.67731 17.5291 2.68981 17.2795L3.18739 6.68884C3.20799 6.21404 3.41121 5.76555 3.75461 5.43703C4.09802 5.1085 4.55507 4.92534 5.03031 4.92578H14.9698C15.445 4.92534 15.9021 5.1085 16.2455 5.43703C16.5889 5.76555 16.7921 6.21404 16.8127 6.68884L17.3103 17.2795C17.3228 17.5291 17.2844 17.7787 17.1974 18.0131C17.1104 18.2474 16.9766 18.4616 16.8042 18.6426C16.6318 18.8236 16.4244 18.9677 16.1946 19.066C15.9648 19.1643 15.7173 19.2149 15.4674 19.2145ZM5.03031 6.16054C4.86739 6.16054 4.71114 6.22526 4.59593 6.34046C4.48073 6.45567 4.41601 6.61192 4.41601 6.77484L3.91842 17.3409C3.91425 17.4241 3.92706 17.5073 3.95606 17.5854C3.98506 17.6636 4.02965 17.7349 4.08711 17.7953C4.14458 17.8556 4.21372 17.9036 4.29032 17.9364C4.36693 17.9692 4.4494 17.986 4.53272 17.9859H15.4674C15.5507 17.986 15.6332 17.9692 15.7098 17.9364C15.7864 17.9036 15.8555 17.8556 15.913 17.7953C15.9704 17.7349 16.015 17.6636 16.044 17.5854C16.073 17.5073 16.0858 17.4241 16.0817 17.3409L15.5841 6.75027C15.5841 6.58735 15.5194 6.4311 15.4042 6.31589C15.289 6.20069 15.1327 6.13597 14.9698 6.13597L5.03031 6.16054Z" fill="#1E1E1E" />
        </svg>

    )
}

export default BasketIcon