import React from 'react'
import Navbar from './Navbar'

const Index = () => {
    return (
        <>
            <Navbar />
        </>
    )
}

export default Index