import React from "react";

const MailIcon = (props: any) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.939 0C16.28 0 17.57 0.53 18.519 1.481C19.469 2.43 20 3.71 20 5.05V12.95C20 15.74 17.73 18 14.939 18H5.06C2.269 18 0 15.74 0 12.95V5.05C0 2.26 2.259 0 5.06 0H14.939ZM16.5299 6.54033L16.6099 6.46033C16.8489 6.17033 16.8489 5.75033 16.5989 5.46033C16.4599 5.31133 16.2689 5.22033 16.0699 5.20033C15.8599 5.18933 15.6599 5.26033 15.5089 5.40033L10.9999 9.00033C10.4199 9.48133 9.58893 9.48133 8.99993 9.00033L4.49993 5.40033C4.18893 5.17033 3.75893 5.20033 3.49993 5.47033C3.22993 5.74033 3.19993 6.17033 3.42893 6.47033L3.55993 6.60033L8.10993 10.1503C8.66993 10.5903 9.34893 10.8303 10.0599 10.8303C10.7689 10.8303 11.4599 10.5903 12.0189 10.1503L16.5299 6.54033Z" fill="white" />
    </svg>

  );
};

export default MailIcon;
