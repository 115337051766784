import { ThemeProvider } from '@mui/material/styles'
import { AppStyled } from 'App.style'
import Layout from 'layout/container/Layout'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { LightTheme } from 'styles/theme/LightTheme'
import { useContext } from 'react';
import { LayoutContext } from 'layout/context'
import { useMemo } from 'react';
import { DarkTheme } from 'styles/theme/DarkTheme'

const ThemeWrapper = () => {

    const { state: { mode }, actions: { } } = useContext(LayoutContext);


    const body = document.getElementsByTagName("body");

    const theme = useMemo(() => {
        if (mode === "dark") {
            body[0]?.classList.add("dark");
            body[0]?.classList.remove("light");
            return DarkTheme
        } else {
            body[0]?.classList.add("light");
            body[0]?.classList.remove("dark");
            return LightTheme
        }
    }, [mode])


    return (
        <ThemeProvider theme={theme}>
            <AppStyled>
                <Layout />
                <Toaster containerStyle={{ zIndex: "100000" }} />
            </AppStyled>
        </ThemeProvider>
    )
}

export default ThemeWrapper